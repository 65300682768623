export default[
    {
        path: '/deportes',
        component: () => import(/* webpackChunkName: "deportes" */'@/views/deportes/DeportesMain.vue'),
        children:[
            {
                path: '/',
                name: 'deportesPrincipal',
                component: () => import(/* webpackChunkName: "deportesPrincipal" */'@/views/deportes/DeportesPrincipal.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
        ],
    },
]