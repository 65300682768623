export default {
    namespaced: true,

    state: {
        catalogos: {
            catalogoAdscripcion:"/catalogos/catalogoAdscripcion",
            catalogoPuesto:"/catalogos/catalogoPuesto",
            catalogoAdscripcionSignin:"/catalogos/catalogoAdscripcionSignin",
            catalogoPuestoSignin:"/catalogos/catalogoPuestoSignin",
            catalogoCategorias:"/catalogos/catalogoCategorias",
            catalogoTiposApoyo:"/catalogos/catalogoTiposApoyo",
        },
        deportes:{
            list: "/deportes/list",
            guarda: "/deportes/guarda",
        },
        encuestas:{
            guardaEncuesta:"/encuestas/guardaEncuesta",
            listEncuesta:"/encuestas/listEncuesta",
        },
        trabajadores:{
            guardaTrabajador:"/trabajador/guardaTrabajador",
            guardaTrabajadorSignin:"/trabajador/guardaTrabajadorSignin",
            guardaTrabajadorUsuario:"/trabajador/guardaTrabajadorUsuario",
            listTrabajador:"/trabajador/listTrabajador",
            listTrabajadorSignin:"/trabajador/listTrabajadorSignin",

            reporteTrabajadores:"/trabajador/reporteTrabajadores",

            guardaActividad:"/trabajador/guardaActividad",
            listaActividad:"/trabajador/listaActividad",

            listApoyos:"/trabajador/listApoyos",
            guardaApoyo:"/trabajador/guardaApoyo",

            guardaFoto:"/trabajador/guardaFoto",
            getFoto:"/trabajador/getFoto",

            subeTarjeton:"/trabajador/subeTarjeton",
            getBoleto:"/trabajador/getBoleto",
            listBoletos:"/trabajador/listBoletos",
            guardaBoleto:"/trabajador/guardaBoleto",
            getTarjeton:"/trabajador/getTarjeton",
        },
        security:{
            buscaCuenta:"/security/buscaCuenta",
            cambiaPasswordAdmin:"/security/cambiaPasswordAdmin",
            cambiaPasswordUsuario:"/security/cambiaPasswordUsuario",
        },
        conflicto:{
            actualizaConflicto:"/conflictos/actualizaConflicto",
            guardaActividad:"/conflictos/guardaActividad",
            guardaConflicto:"/conflictos/guardaConflicto",
            listConflictos:"/conflictos/listConflictos",

            guardaConflictoImagen:"/conflictos/guardaConflictoImagen",
            getConflictoImagen:"/conflictos/getConflictoImagen",
        },
        juguetes:{
            guardaSolicitud:"/juguetes/guardaSolicitud",
            listSolicitudes:"/juguetes/listSolicitudes",
            listJuguetes:"/juguetes/listJuguetes",
        },
    },
}