import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import admin from './sections/admin'
import becas from './sections/becas'
import boletos from './sections/boletos'
import bolsatrabajo from './sections/bolsatrabajo'
import conflictos from './sections/conflictos'
import deportes from './sections/deportes'
import generales from './sections/generales'
import juguetes from './sections/juguetes'
import trabajador from './sections/trabajador'

const routes = [
    ...admin,
    ...becas,
    ...boletos,
    ...bolsatrabajo,
    ...conflictos,
    ...deportes,
    ...generales,
    ...juguetes,
    ...trabajador,
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/layout/HomeView.vue')
    },
    {
        path: '/principal',
        name: 'principal',
        //component: Home
        component: () => import(/* webpackChunkName: "principal" */ '@/views/PrincipalView.vue'),
        meta: {
            requiresAuth: true,
            roles:['ROLE_TRABAJADOR'],
        },
    },
    {
        path: '/signin',
        name: 'signin',
        component: () => import(/* webpackChunkName: "signin" */ '@/views/SigninMain.vue'),
        meta: {
            requiresAuth: false,
            //roles:['ROLE_TRABAJADOR'],
        },
    },

    ]

    const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
    })

export default router
